import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import money from "astrid-helpers/src/money";

import CostDropdownMenu from "../../../../costs/components/CostDropdownMenu/CostDropdownMenu";
import useCostTypes from "../../../../costs/hooks/useCostTypes";
import { costPriceAccessor, totalAccessor } from "../../../../costs/utils/costAccessors";

export default function useCostsColumns() {
	const { t } = useTranslation();

	const costTypes = useCostTypes();

	return useMemo(
		() => [
			{
				id: "created",
				Header: t("created", "Created"),
				accessor: ({ created }) => created?.toDate().getTime() || "",
				Cell: ({ value }) => new Date(value).toLocaleString(),
				collapsing: true,
			},
			{
				id: "production.title",
				filter: "text",
				Header: t("production"),
				collapsing: true,
			},
			{
				id: "production.language",
				filter: "select",
				Header: t("language", "Language"),
				collapsing: true,
			},
			{
				id: "secondParty.name",
				filter: "text",
				Header: t("secondParty", "Second party"),
				collapsing: true,
			},
			{
				id: "price.type",
				filter: "select",
				Header: t("type", "Type"),
				accessor: ({ price }) => costTypes[price.type],
				collapsing: true,
			},
			{
				id: "price.price",
				filter: "text",
				Header: t("price", "Price"),
				accessor: costPriceAccessor,
				collapsing: false,
			},
			{
				id: "total",
				Header: t("sum", "Sum"),
				textAlign: "right",
				accessor: totalAccessor,
				collapsing: true,
				Cell: ({ value, row }) => money(value, row.original.price.currency),
			},
			{
				id: "menu",
				textAlign: "right",
				collapsing: true,
				disableSortBy: true,
				Cell: ({ row }) => <CostDropdownMenu cost={row.original} />,
			},
		],
		[costTypes, t],
	);
}
