import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import moment from "moment";
import { decodeAudioData } from "standardized-audio-context";
import uniqid from "uniqid";

import { Button, Divider, Form, Icon, Label, Loader, Popup, Segment } from "semantic-ui-react";

import React, { Component } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { withTranslation } from "react-i18next";

import { base, db, firebase, notifyUsers } from "astrid-firebase";
import addEvent from "astrid-firebase/src/utils";
import { batch, getCollection } from "astrid-firestore/src/helpers";
import { addMasterFileToArticle, queueMasterFileForProcessing } from "astrid-web/src/components/production/fnc";
import { secToDuration, toDate } from "astrid-web/src/helpers/fnc";

import UploadButton from "../../UploadButton";

import MasterUploadRetry from "./MasterUploadRetry";

const renderMaster = firebase.functions().httpsCallable("render-master", { timeout: 1000 * 60 * 5 });
const moveFilesToStudio = firebase.functions().httpsCallable("moveFilesToStudio", { timeout: 1000 * 60 * 5 });

const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};

const move = (source, destination, droppableSource, droppableDestination) => {
	const sourceClone = Array.from(source);
	const destClone = Array.from(destination);
	const [removed] = sourceClone.splice(droppableSource.index, 1);

	destClone.splice(droppableDestination.index, 0, removed);

	const result = {};
	result[droppableSource.droppableId] = sourceClone;
	result[droppableDestination.droppableId] = destClone;

	return result;
};

class MasterUploads extends Component {
	state = {
		loading: true,
		source: {},
		modifiedSettings: false,
		isUploading: false,
		uploadFiles: {},
		loadingProduction: false,
		loadingPublishers: false,
		analyzingUploads: null,
	};

	uploadQueue = [];

	UNSAFE_componentWillMount() {
		base.bindDoc("/productions/" + this.props.productionId + "/meta/master", {
			context: this,
			state: "source",
			then() {
				this.setState({ loading: false });
			},
		});
	}

	componentWillUnmount() {
		if (this.state.uploadFiles && Object.keys(this.state.uploadFiles).length) {
			// cancel uploads
			Object.values(this.state.uploadFiles).forEach((upload) => {
				if (upload.task) upload.task.cancel();
			});
		}
	}

	loadProductions = (publisherId) => {
		this.setState({ loadingProduction: true });

		db.collection("productions")
			.where("publisher", "==", publisherId || this.props.production.publisher)
			.where("status", "in", ["done", "production"])
			.get()
			.then((querySnapshot) => {
				const docs = [];
				querySnapshot.forEach(function (doc) {
					const data = doc.data();
					data.id = doc.id;
					docs.push(data);
				});

				return docs;
			})
			.then((data) => {
				const key = publisherId ? "otherPublisherProductions" : "productions";
				this.setState({
					loadingProduction: false,
					[key]: data.sort((a, b) => (a.title > b.title ? 1 : -1)),
				});
			});
	};

	loadPublishers = () => {
		this.setState({ loadingPublishers: true });

		db.collection("organizations")
			.where("type", "==", "publisher")
			.get()
			.then((querySnapshot) => {
				const docs = [];
				querySnapshot.forEach(function (doc) {
					const data = doc.data();
					data.id = doc.id;
					docs.push(data);
				});

				return docs;
			})
			.then((data) => {
				this.setState({
					loadingPublishers: false,
					publishers: data.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)),
				});
			});
	};

	getFiles = (article, includeMissing) =>
		(get(this.props.production, "master." + article + ".files") || []).filter(
			(file) => includeMissing || this.getFile(file),
		);

	getFile = (id) => this.state.source[id] || this.state.uploadFiles[id];

	onDragEnd = (result) => {
		const { source, destination } = result;

		// dropped outside the list
		if (!destination) {
			return;
		}

		let data;
		if (source.droppableId === destination.droppableId) {
			const files = reorder(this.getFiles(source.droppableId, true), source.index, destination.index);

			// BAD way of instantly updating position...
			this.props.production.master[source.droppableId].files = files;

			// actual update
			data = {
				["master." + source.droppableId + ".files"]: files,
				["master." + source.droppableId + ".pushed"]: false,
				["master." + source.droppableId + ".settings.approved"]: false,
			};
		} else {
			// should look for article.single instead, but for now just cd
			if (destination.droppableId === "cd" && this.getFiles(destination.droppableId).length > 0) {
				return;
			}

			const result = move(
				this.getFiles(source.droppableId, true),
				this.getFiles(destination.droppableId, true),
				source,
				destination,
			);

			// BAD way of instantly updating position...
			if (this.props.production.master[source.droppableId])
				this.props.production.master[source.droppableId].files = result[source.droppableId];
			if (this.props.production.master[destination.droppableId])
				this.props.production.master[destination.droppableId].files = result[destination.droppableId];

			// actual update
			data = {
				["master." + source.droppableId + ".files"]: result[source.droppableId],
				["master." + source.droppableId + ".pushed"]: false,
				["master." + source.droppableId + ".settings.approved"]: false,
				["master." + destination.droppableId + ".files"]: result[destination.droppableId],
				["master." + destination.droppableId + ".pushed"]: false,
				["master." + destination.droppableId + ".settings.approved"]: false,
			};

			if (source.droppableId === "parking" && destination.droppableId === "proof") {
				data["master.proof.fire"] = false;
			}
		}

		base.updateDoc("productions/" + this.props.productionId, data);
	};

	sortArticle = (article) => {
		const files = this.getFiles(article, true);

		files.sort((a, b) => {
			const aFile = this.getFile(a) || a;
			const bFile = this.getFile(b) || b;

			return aFile.name > bFile.name ? 1 : -1;
		});

		const data = {
			["master." + article + ".files"]: files,
			["master." + article + ".pushed"]: false,
			["master." + article + ".settings.approved"]: false,
		};
		base.updateDoc("productions/" + this.props.productionId, data);
	};

	moveToArticle = (from, to) => {
		const files = this.getFiles(from, true);
		const data = {
			["master." + from + ".files"]: firebase.firestore.FieldValue.arrayRemove(...files),
			["master." + from + ".pushed"]: false,
			["master." + from + ".settings.approved"]: false,
			["master." + to + ".files"]: firebase.firestore.FieldValue.arrayUnion(...files),
			["master." + to + ".pushed"]: false,
			["master." + to + ".settings.approved"]: false,
		};
		base.updateDoc("productions/" + this.props.productionId, data);
	};

	moveNewestToNormal = () => {
		const files = this.getFiles("parking", true);

		const newestFirstFile = files
			.filter((file) => this.getFile(file).name.endsWith("001.wav") || this.getFile(file).name.startsWith("001_"))
			.sort((a, b) => {
				const aFile = this.getFile(a) || a;
				const bFile = this.getFile(b) || b;

				return aFile.created < bFile.created ? 1 : -1;
			})[0];

		if (newestFirstFile) {
			const created = this.getFile(newestFirstFile).created;
			const newFiles = files.filter((file) => this.getFile(file).created >= created);

			const data = {
				"master.parking.files": firebase.firestore.FieldValue.arrayRemove(...newFiles),
				"master.total.files": firebase.firestore.FieldValue.arrayUnion(...newFiles),
				"master.total.settings.approved": false,
			};
			base.updateDoc("productions/" + this.props.productionId, data);
		}
	};

	moveToProduction = async (article, destProd) => {
		const files = this.getFiles(article, true);
		const fromProd = {
			["master." + article + ".files"]: firebase.firestore.FieldValue.arrayRemove(...files),
			["master." + article + ".pushed"]: false,
			["master." + article + ".settings.approved"]: false,
		};
		const fromMeta = {};
		const toMeta = {};

		files.forEach((file) => {
			fromMeta[file] = firebase.firestore.FieldValue.delete();

			const fileMeta = this.getFile(file);
			if (fileMeta) toMeta[file] = fileMeta;
		});

		const prod =
			this.state.productions?.find((prod) => prod.id === destProd) ||
			this.state.otherPublisherProductions?.find((prod) => prod.id === destProd);

		const destArticle = get(prod, "master." + article) ? article : "parking";

		const toProd = {
			["master." + destArticle + ".files"]: firebase.firestore.FieldValue.arrayUnion(...files),
			["master." + destArticle + ".pushed"]: false,
			["master." + destArticle + ".settings.approved"]: false,
		};

		console.log({ article, destProd, fromProd, fromMeta, toProd, toMeta });

		// set destination doc first
		await db.collection("productions").doc(destProd).update(toProd);
		await db.collection("productions").doc(destProd).collection("meta").doc("master").set(toMeta, { merge: true });

		// then remove from current
		await db.collection("productions").doc(this.props.productionId).update(fromProd);
		await db
			.collection("productions")
			.doc(this.props.productionId)
			.collection("meta")
			.doc("master")
			.set(fromMeta, { merge: true });

		// store event
		await db
			.collection("productions")
			.doc(this.props.productionId)
			.collection("events")
			.add({
				user: this.props.user.uid,
				email: this.props.profile.email,
				data: { movedMasterFiles: { article, destProd } },
				time: firebase.firestore.FieldValue.serverTimestamp(),
			});
	};

	isUploading = (to) => {
		window.onbeforeunload = () => (to ? true : undefined);
		this.setState({ isUploading: to });
	};

	getStorage = () =>
		firebase.storage().refFromURL(window.ES.stage ? "gs://stage-earselect-static" : "gs://earselect-static");

	uploadsInProgress = 0;
	uploadAudio = async (e) => {
		const { productionId, production, user, t } = this.props;
		const article = e.target.name;

		const isSubcontractor = production?.subcontractor === user.uid;

		// file from input (convert to array)
		const files = Array.from(e.target.files);

		files.sort((a, b) => {
			const aName = a.fullPath || a.name;
			const bName = b.fullPath || b.name;

			return aName > bName ? 1 : -1;
		});

		if (!files.length) return;

		if (files.find((file) => !(file.type.startsWith("audio/wav") || file.type.startsWith("audio/x-wav")))) {
			window.alert(t("uploadFileTypeWarning"));
			return;
		}

		// analyze files
		this.setState({ analyzingUploads: article });
		const AudioContext = window.AudioContext || window.webkitAudioContext;

		await Promise.all(
			files.map(
				(file) =>
					new Promise(async (resolve, reject) => {
						const { name, size } = file;

						// get channels from audio buffer
						const blob = file.slice(0, 1e6);
						const buffer = Blob.prototype.arrayBuffer
							? await blob.arrayBuffer()
							: await new Promise((resolve) => {
									let fr = new FileReader();
									fr.onload = () => {
										resolve(fr.result);
									};
									fr.readAsArrayBuffer(blob);
							  });

						const audioCtx = new AudioContext();
						const { numberOfChannels: channels } = await decodeAudioData(audioCtx, buffer);

						// get duration from html5 audio element
						const el = document.createElement("audio");
						const objectUrl = URL.createObjectURL(file);

						el.addEventListener("canplaythrough", () => {
							const duration = el.duration;
							const kbps = (size * 8) / 1000 / duration;

							const info = {
								name,
								size,
								duration,
								channels,
								kbps,
							};

							URL.revokeObjectURL(objectUrl);

							// reject if audio file is more than 4gb and some other basic checks
							const sizeLimit = 4 * 1024 * 1024 * 1024;
							if (duration && channels > 0 && channels < 3 && kbps > 350 && size && size < sizeLimit) {
								resolve(info);
							} else {
								reject(info);
							}
						});

						el.src = objectUrl;
					}),
			),
		)
			.then((result) => {
				console.log("All audio files are good", result);

				// block closing page
				this.isUploading(true);

				// add to queue
				const uploadFiles = { ...this.state.uploadFiles };
				const newFiles = [];
				files.forEach((file, index) => {
					const fileid = uniqid();
					newFiles.push(fileid);
					uploadFiles[fileid] = { file, name: file.name, progress: 0 };
					this.uploadQueue.push(fileid);

					addMasterFileToArticle(productionId, article, fileid);
				});
				this.setState(
					{
						uploadFiles,
					},
					() => {
						// for parking files, ask if they are mastered?
						if (article === "parking") {
							this.setState({ askIfUploadsAreMastered: newFiles });
						} else {
							// start two uploads if not already going
							this.startUploading();
						}
					},
				);
			})
			.catch((result) => {
				console.log("Some audio files are not good", result);

				// guess the wav format based on bitrate per channel
				const spec = [
					{ kbps: 352.8, bits: 16, samplerate: 22.05 },
					{ kbps: 384, bits: 16, samplerate: 24 },
					{ kbps: 512, bits: 16, samplerate: 32 },
					{ kbps: 705, bits: 16, samplerate: 44.1 },
					{ kbps: 768, bits: 16, samplerate: 48 },
					{ kbps: 1536, bits: 16, samplerate: 96 },

					{ kbps: 529.2, bits: 24, samplerate: 22.05 },
					{ kbps: 576, bits: 24, samplerate: 24 },
					{ kbps: 1058.4, bits: 24, samplerate: 44.1 },
					{ kbps: 1152, bits: 24, samplerate: 48 },
					{ kbps: 2304, bits: 24, samplerate: 96 },

					{ kbps: 1024, bits: 32, samplerate: 32 },
					{ kbps: 1411.2, bits: 32, samplerate: 44.1 },
					{ kbps: 1536, bits: 32, samplerate: 48 },
					{ kbps: 3072, bits: 32, samplerate: 96 },
				].sort((a, b) => a.kbps - b.kbps);

				const channelBitrate = result.kbps / result.channels;

				if (channelBitrate < 350) {
					window.alert(t("lowResolutionWarning", { name: result.name }));
				} else {
					const guess = spec.find((s) => s.kbps + 10 > channelBitrate) || {};

					const maxSeconds = (4 * 1024 * 1024 * 8) / result.kbps;
					const maxDuration = secToDuration(maxSeconds, false);
					const maxHours = maxSeconds / 60 / 60;
					const h = Math.floor(maxHours);
					const m = Math.floor((maxHours - h) * 60);
					const audioChannelType = result.channels > 1 ? "stereo" : "mono";

					console.log(
						result.name,
						"=",
						guess.bits,
						guess.samplerate,
						result.channels > 1 ? "stereo" : "mono",
						maxDuration,
					);

					/* window.alert(
						`Filen ${result.name} är större än wav-formatet tillåter. \n\nEn fil med dessa inställningar (${
							guess.bits
						} bit, ${guess.samplerate}kHz, ${
							result.channels > 1 ? "stereo" : "mono"
						}) får max vara ${h} timmar och ${m} minuter lång.`,
					); */
					window.alert(
						t("fileSizeTooLargeWav", {
							bit: guess.bits,
							sampleRate: guess.samplerate,
							audioChannelType: audioChannelType,
							hours: h,
							minutes: m,
						}),
					);
				}
			});

		this.setState({ analyzingUploads: null });
	};

	startUploading = () => {
		const { productionId, production, user, t } = this.props;

		const isSubcontractor = production?.subcontractor === user.uid;

		if (this.uploadsInProgress < 2) this.nextUpload();
		if (this.uploadsInProgress < 2) this.nextUpload();

		if (isSubcontractor) {
			db.collection("productions").doc(productionId).update({
				"master.proof.fire": true,
			});
			const lastNotified = production.subcontractorUploadNotification?.toDate();
			const anHourAgo = new Date(Date.now() - 1000 * 60 * 60);
			if (production.manager?.length && (!lastNotified || lastNotified < anHourAgo)) {
				const host = window.ES.stage ? "https://stage.astrid.fm" : "https://astrid.fm";
				const subject = t("notifyUploadedFilesSubject", "Subcontractor uploaded new files");
				const message = t("notifyUploadedFilesMessage", {
					title: production.title,
					url: `${host}/production/${this.props.productionId}/audiobook`,
				});
				notifyUsers([production.manager], this.props.userMap, subject, message);
				db.collection("productions").doc(productionId).update({
					subcontractorUploadNotification: firebase.firestore.FieldValue.serverTimestamp(),
				});
			}
		}
	};

	nextUpload = () => {
		// get next in line
		const fileid = this.uploadQueue.shift();
		if (!fileid) return;

		const { file, name, unmastered } = this.state.uploadFiles[fileid];
		const { productionId, profile } = this.props;
		const storageRef = this.getStorage();

		// start upload
		this.uploadsInProgress++;
		const path = "masterDelivery/" + productionId + "/source/" + fileid + "_" + name;
		const uploadTask = storageRef.child(path).put(file);

		// upload status events
		uploadTask.on(
			firebase.storage.TaskEvent.STATE_CHANGED,
			(snapshot) => {
				// progress
				const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
				this.setState({
					uploadFiles: {
						...this.state.uploadFiles,
						[fileid]: { ...this.state.uploadFiles[fileid], progress, task: uploadTask },
					},
				});
			},
			(error) => {
				// error
				console.log(error);
			},
			() => {
				// success, start next upload
				this.nextUpload();
				this.uploadsInProgress--;

				const userName =
					profile?.firstName && profile?.lastName ? `${profile.firstName} ${profile.lastName}` : "";
				// store in db
				uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
					queueMasterFileForProcessing({
						productionId,
						fileid,
						name,
						path,
						downloadURL,
						uid: this.props.user.uid,
						email: profile.email,
						unmastered,
						userName,
					});

					// delete progress
					const newData = { ...this.state.uploadFiles };
					delete newData[fileid];
					this.setState({ uploadFiles: newData });

					// allow leaving page again
					if (!Object.keys(newData).length) this.isUploading(false);
				});
			},
		);
	};

	answerIfUploadsAreMastered = (e) => {
		const { askIfUploadsAreMastered, uploadFiles } = this.state;
		if (!e.target.getAttribute("mastered")) {
			// not mastered, set flag
			const newUploadFiles = cloneDeep(uploadFiles);
			askIfUploadsAreMastered.forEach((fileid) => {
				newUploadFiles[fileid].unmastered = true;
			});

			this.setState(
				{
					uploadFiles: newUploadFiles,
				},
				this.startUploading,
			);
		} else {
			// already mastered, just upload
			this.startUploading();
		}
		this.setState({ askIfUploadsAreMastered: false });
	};

	resetMaster = ({ file, productionId }) => {
		const masterDoc = db.collection("productions").doc(productionId).collection("meta").doc("master");

		masterDoc.set(
			{
				[file]: {
					mastered: firebase.firestore.FieldValue.delete(),
					mp4_master: firebase.firestore.FieldValue.delete(),
					json_master: firebase.firestore.FieldValue.delete(),
					waves_master: firebase.firestore.FieldValue.delete(),
				},
			},
			{ merge: true },
		);
	};

	masterPart = ({ file, info, productionId }) => {
		const masterDoc = db.collection("productions").doc(productionId).collection("meta").doc("master");

		if (info.status === "done" || info.status === "error") {
			this.isUploading(true);
			masterDoc.set(
				{
					[file]: {
						status: "queued",
					},
				},
				{ merge: true },
			);

			renderMaster({ productionId, masterId: file, version: 3 }).then((res) => {
				this.isUploading(false);
				masterDoc.set(
					{
						[file]: {
							status: "master",
						},
					},
					{ merge: true },
				);
			});
		} else if (!info.masterAfterConversion) {
			console.log("master after conversion");

			masterDoc.set(
				{
					[file]: {
						masterAfterConversion: true,
					},
				},
				{ merge: true },
			);
		}
	};

	deletePart = ({ file, productionId }) => {
		const { t } = this.props;

		if (window.confirm(t("sureWantToDeleteFile"))) {
			const prodDoc = db.collection("productions").doc(productionId);

			prodDoc.update({
				"master.parking.files": firebase.firestore.FieldValue.arrayRemove(file),
			});

			addEvent({
				productionId,
				user: this.props.user.uid,
				email: this.props.profile.email,
				data: { deleteMasterFile: file },
			});
		}
	};

	deleteAll = (type) => {
		if (window.confirm(this.props.t("sureWantToDeleteAll"))) {
			db.collection("productions")
				.doc(this.props.productionId)
				.update({
					[`master.${type}.files`]: [],
					[`master.${type}.pushed`]: false,
				});
		}
	};

	package = (type) => {
		const { master } = this.props.production;
		const { files } = master[type];
		const id = uniqid();

		db.collection("productions")
			.doc(this.props.productionId)
			.collection("meta")
			.doc("master")
			.update(
				files.reduce(
					(map, file) => ({
						...map,
						[`${file}.package`]: id,
					}),
					{},
				),
			);
	};

	sendToProof = async () => {
		this.setState({ isSendingProof: true });

		let start = 0;

		const ref = db.collection("productions").doc(this.props.productionId);
		const collection = ref.collection("parts");
		const parts = await getCollection(collection);

		const updates = parts.map((part) => ["delete", part.ref.path]);

		for (const file of this.getFiles("proof")) {
			const { mp4, name, duration, mastered, original } = this.getFile(file);
			const stop = start + duration * 1000;

			const bucket = mp4.url.split("/b/")[1].split("/o/")[0];

			updates.push([
				"set",
				collection.doc().path,
				{
					start,
					stop,
					name,
					aac: {
						bucket,
						...mp4,
					},
					master: {
						bucket,
						...(mastered || original),
					},
					status: "completed",
				},
			]);

			start = stop;
		}

		updates.push([
			"update",
			ref.path,
			{
				render: firebase.firestore.FieldValue.delete(),
				"master.proof.pushed": true,
				"master.proof.version": firebase.firestore.FieldValue.increment(1),
				"progress.edited.time": start,
				"progress.recorded.time": start,
			},
		]);

		await batch(db, updates);

		this.setState({ isSendingProof: false, isSentToProof: true });

		setTimeout(() => {
			this.setState({ isSentToProof: false });
		}, 3000);
	};

	moveFilesToStudio = async () => {
		const confirm = !window.confirm(
			this.props.t(
				"sureAboutReplacingStudioClips",
				"Are you sure you want to replace all clips in the studio? This is a destructive action and cannot be undone.",
			),
		);

		if (confirm) {
			return;
		}

		this.setState({ movingFilesToStudio: true });

		await moveFilesToStudio({
			article: "parking",
			productionId: this.props.productionId,
		});

		this.setState({ movingFilesToStudio: false });
	};

	render() {
		const { profile, t, production, productionId, isPublisher, isProducer } = this.props;
		const {
			isSentToProof,
			isSendingProof,
			isUploading,
			loading,
			loadingProduction,
			productions,
			otherPublisherProductions,
			publishers,
			askIfUploadsAreMastered,
			movingFilesToStudio,
		} = this.state;

		const isReadyForProof =
			!isUploading &&
			!isSentToProof &&
			!isSendingProof &&
			!production?.master?.proof?.pushed &&
			!this.getFiles("proof").find((file) => this.getFile(file)?.status !== "done");

		const fileTooLong = this.getFiles("proof").find((file) => this.getFile(file)?.duration >= 5400);

		const articles = [{ id: "parking", title: t("parking"), icon: "dolly flatbed" }];

		const isSubcontractor = production?.subcontractor === this.props.user.uid;
		const isAdmin = profile.permissions.producer?.[production.producer]?.includes("producerAdmin");

		if (!isSubcontractor) {
			articles.push({
				id: "proof",
				title: t("proof"),
				icon: "assistive listening systems",
			});

			articles.push({ id: "total", title: t("audioBook"), icon: "box" });

			if (!isPublisher && (production.deliveryCD || production.deliveryMP3CD)) {
				articles.push({ id: "cd", title: t("cdIntro"), single: true, icon: "dot circle" });
			}

			if (get(production, "deliveryParts")) {
				get(production, "deliveryParts").forEach((part, index) => {
					articles.push({
						id: "part_" + index,
						title: "Del " + (index + 1),
						icon: "part",
					});
				});
			}
		}

		return (
			<div
				className={
					"production-delivery-article clear" +
					(articles.length === 2 ? " half" : articles.length === 1 ? " full" : "")
				}
			>
				{/* <Prompt when={!!isUploading} message={t("sureAboutOpenAnotherPage")} /> */}
				<Divider horizontal>
					{t("uploadSoundFile")} <Icon name="cloud upload" color="grey" />
				</Divider>
				{loading ? (
					<Loader active inline="centered" content={t("loadSoundfiles")} />
				) : (
					<DragDropContext onDragEnd={this.onDragEnd}>
						{articles.map((article) => (
							<div className="master-uploadlist" key={article.id}>
								<div className="master-uploadlist-header">
									{article.icon === "part" ? (
										<Icon circular inverted color="grey" name="percent" size="small" />
									) : (
										<>
											<Icon name={article.icon} size="large" />
										</>
									)}{" "}
									<b>
										{article.title} : {this.getFiles(article.id).length} {t("files")}
									</b>
									<br />
									{!!this.getFiles(article.id, true).length && !isSubcontractor && (
										<>
											{isAdmin && article.id === "parking" && (
												<Popup
													inverted
													size="mini"
													trigger={
														<Icon
															name="microphone"
															disabled={
																movingFilesToStudio ||
																production?.bookedRecorders?.length
															}
															onClick={() => {
																this.moveFilesToStudio();
															}}
														/>
													}
													content={t(
														"moveFilesToStudio",
														"Replace all studio clips with current files",
													)}
												/>
											)}

											{article.id === "parking" && (
												<Popup
													inverted
													size="mini"
													trigger={
														<Icon
															name="gem"
															onClick={() => {
																this.moveNewestToNormal();
															}}
														/>
													}
													content={t("moveCurrentFiles")}
												/>
											)}

											<Icon
												name="sort numeric down"
												onClick={() => {
													this.sortArticle(article.id);
												}}
											/>

											<Popup
												on="click"
												size="small"
												trigger={<Icon name="share square" onClick={() => {}} />}
												content={
													<div>
														<h5>{t("moveFilesTo")}</h5>
														<Form.Select
															options={[
																{
																	key: "NONE",
																	value: "NONE",
																	text: t("anotherArticle"),
																},
																...articles.map((art) => ({
																	key: art.id,
																	value: art.id,
																	text: art.title,
																	disabled: art.id === article.id,
																})),
															]}
															value="NONE"
															onChange={(e, data) => {
																if (
																	data.value !== "NONE" &&
																	(e.type !== "keydown" || e.key === "Enter")
																)
																	this.moveToArticle(article.id, data.value);
															}}
														/>
														<br />

														{isProducer?.includes?.("producerAdmin") && (
															<>
																<Form.Select
																	options={[
																		{
																			key: "NONE",
																			value: "NONE",
																			text: t("anotherProduction"),
																		},
																		...(productions
																			? productions
																					.filter(
																						(prod) =>
																							prod.id !== productionId,
																					)
																					.map((prod) => ({
																						text: prod.title,
																						key: prod.id,
																						value: prod.id,
																					}))
																			: []),
																	]}
																	value="NONE"
																	search
																	deburr
																	loading={loadingProduction}
																	onOpen={() => {
																		if (!productions) this.loadProductions();
																	}}
																	onChange={(e, data) => {
																		if (
																			data.value !== "NONE" &&
																			(e.type !== "keydown" || e.key === "Enter")
																		)
																			this.moveToProduction(
																				article.id,
																				data.value,
																			);
																	}}
																/>
																<Divider />
																<Form.Select
																	options={[
																		{
																			key: "NONE",
																			value: "NONE",
																			text: t("anotherPublisher"),
																		},
																		...(publishers
																			? publishers
																					.filter(
																						(prod) =>
																							prod.id !==
																							this.props.production
																								.publisher,
																					)
																					.map((prod) => ({
																						text: prod.name,
																						key: prod.id,
																						value: prod.id,
																					}))
																			: []),
																	]}
																	value="NONE"
																	search
																	deburr
																	loading={this.state.loadingPublishers}
																	onOpen={() => {
																		if (!publishers) this.loadPublishers();
																	}}
																	onChange={(e, data) => {
																		if (
																			data.value !== "NONE" &&
																			(e.type !== "keydown" || e.key === "Enter")
																		) {
																			this.loadProductions(data.value);
																		}
																	}}
																/>
															</>
														)}
														<br />

														{otherPublisherProductions && (
															<Form.Select
																options={[
																	{
																		key: "NONE",
																		value: "NONE",
																		text: t("chooseProduction"),
																	},
																	...(otherPublisherProductions
																		? otherPublisherProductions
																				.filter(
																					(prod) => prod.id !== productionId,
																				)
																				.map((prod) => ({
																					text: prod.title,
																					key: prod.id,
																					value: prod.id,
																				}))
																		: []),
																]}
																value="NONE"
																search
																deburr
																loading={loadingProduction}
																onChange={(e, data) => {
																	if (
																		data.value !== "NONE" &&
																		(e.type !== "keydown" || e.key === "Enter") &&
																		window.confirm(t("sureAboutMovingSoundFiles"))
																	)
																		this.moveToProduction(article.id, data.value);
																}}
															/>
														)}
													</div>
												}
											/>
											<Icon
												name="trash alternate"
												onClick={() => {
													this.deleteAll(article.id);
												}}
											/>
											<br />
										</>
									)}
								</div>
								<Droppable droppableId={article.id} isDropDisabled={false}>
									{(provided, snapshot) => (
										<div
											ref={provided.innerRef}
											className={
												"master-uploadlist-area" +
												(snapshot.isDraggingOver ? " over" : "") +
												(article.single && !!this.getFiles(article.id).length ? " full" : "") +
												(this.getFiles(article.id).length ? "" : " empty")
											}
											data-uploadAreaText={t("uploadAreaText")}
										>
											{this.getFiles(article.id, true).map((file, index) => (
												<Draggable key={file} draggableId={file} index={index}>
													{(provided, snapshot) => {
														const info = this.getFile(file);

														return info ? (
															<div
																ref={provided.innerRef}
																{...provided.draggableProps}
																{...provided.dragHandleProps}
																style={provided.draggableProps.style}
																className={
																	"item" + (snapshot.isDragging ? " dragging" : "")
																}
															>
																<div className="flex-stack">
																	<div
																		style={{
																			display: "flex",
																			justifyContent: "space-between",
																			alignItems: "center",
																			width: "100%",
																			marginTop: 0,
																		}}
																	>
																		<b>
																			<Icon
																				name={
																					info.unmastered
																						? "file audio outline"
																						: "file audio"
																				}
																				size="large"
																				color={
																					info.status === "done"
																						? info.unmastered &&
																						  !info.mastered
																							? "blue"
																							: "green"
																						: info.progress
																						? "orange"
																						: info.status
																						? "yellow"
																						: "grey"
																				}
																			/>{" "}
																			{info.name.replace(".wav", "")}
																		</b>
																		{info.status && info.status !== "done" && (
																			<MasterUploadRetry fileId={file} />
																		)}
																	</div>
																	<div>
																		{info.original && info.original.url && (
																			<a
																				href={info.original.url}
																				rel="noopener noreferrer"
																				target="_blank"
																			>
																				original
																			</a>
																		)}{" "}
																		{info.mastered && info.mastered.url && (
																			<a
																				href={info.mastered.url}
																				rel="noopener noreferrer"
																				target="_blank"
																			>
																				master
																			</a>
																		)}{" "}
																		{info.mp4 && info.mp4.url && (
																			<a
																				href={
																					info.mp4_master
																						? info.mp4_master.url
																						: info.mp4.url
																				}
																				rel="noopener noreferrer"
																				target="_blank"
																			>
																				mp4
																			</a>
																		)}{" "}
																		{article.id === "parking" && (
																			<>
																				{info.status !== "master" &&
																					info.original &&
																					info.unmastered &&
																					!info.mastered &&
																					(info.status === "done" ||
																						!info.masterAfterConversion) && (
																						<Label
																							size="tiny"
																							style={{
																								cursor: "pointer",
																							}}
																							className={
																								info.status ===
																									"queued" && info.mp4
																									? "disabled"
																									: ""
																							}
																							onClick={() => {
																								if (
																									!(
																										info.status ===
																											"queued" &&
																										info.mp4
																									)
																								) {
																									this.masterPart({
																										file,
																										info,
																										productionId,
																									});
																								}
																							}}
																						>
																							<Icon
																								name="cloud"
																								color="green"
																							/>
																							{info.status === "queued" &&
																							info.mp4
																								? t("wait")
																								: t("render")}
																						</Label>
																					)}
																				{info.status === "done" &&
																					info.mastered && (
																						<Label
																							size="tiny"
																							style={{
																								cursor: "pointer",
																							}}
																							onClick={() => {
																								this.resetMaster({
																									file,
																									info,
																									productionId,
																								});
																							}}
																						>
																							<Icon
																								name="cloud"
																								color="red"
																							/>
																							{t("reset")}
																						</Label>
																					)}
																			</>
																		)}
																		{info.status === "done" ? (
																			<>
																				<Icon name="clock outline" />
																				{secToDuration(info.duration, false)}
																				{article.id === "parking" && (
																					<Icon
																						name="trash alternate"
																						style={{
																							marginLeft: 5,
																							cursor: "pointer",
																						}}
																						onClick={() => {
																							this.deletePart({
																								file,
																								productionId,
																							});
																						}}
																					/>
																				)}
																			</>
																		) : (
																			<>
																				{info.progress || info.status ? (
																					<Loader
																						inline
																						size="mini"
																						style={{
																							display: "inline-block",
																							margin: "0 .4em",
																						}}
																					/>
																				) : (
																					<Icon name="hourglass half" />
																				)}
																				{info.progress !== undefined
																					? t("uploading") +
																					  (info.progress
																							? ": " + info.progress + "%"
																							: "…")
																					: {
																							queued: t("queued"),
																							start: t("started"),
																							convert: t("converts"),
																							waveform: t("waveform"),
																							upload: t("uploadFile"),
																							master: t("rendering"),
																							error: t("error"),
																					  }[info.status] +
																					  (info.duration &&
																					  info.status !== "master"
																							? t("readyAt") +
																							  moment(
																									new Date(
																										+toDate(
																											info.conversion,
																										) +
																											// estimated processing time
																											(info.duration /
																												(info.stereo
																													? 28
																													: 60)) *
																												1000,
																									),
																							  ).format("LT")
																							: "")}
																			</>
																		)}
																		{info.stereo ? " (stereo)" : " (mono)"}
																	</div>
																</div>
																{moment(info?.created?.toDate?.())?.format(
																	"YYYY-MM-DD, hh:mm",
																)}
															</div>
														) : (
															<div
																ref={provided.innerRef}
																{...provided.draggableProps}
																{...provided.dragHandleProps}
															/>
														);
													}}
												</Draggable>
											))}
											{provided.placeholder}
										</div>
									)}
								</Droppable>
								<div style={{ textAlign: "center" }}>
									{askIfUploadsAreMastered && article.id === "parking" ? (
										<Segment textAlign="center">
											<p>
												<b>{t("isSoundReadyToDel")}</b>
											</p>
											<Button.Group>
												<Button
													content={t("unmasteredSound")}
													color="blue"
													onClick={this.answerIfUploadsAreMastered}
												/>

												<Button
													content={t("masteredSound")}
													color="green"
													onClick={this.answerIfUploadsAreMastered}
													mastered="true"
												/>
											</Button.Group>
										</Segment>
									) : (
										<>
											{/* article.id === "parking" && (
												<Button content={t("package")} color="blue" onClick={this.package} />
											) */}
											{article.id === "proof" ? (
												<Popup
													content={t(
														"removeFilesTooLong",
														"Remove files longer than 90 minutes!",
													)}
													open={fileTooLong}
													disabled={!fileTooLong}
													position="bottom center"
													size="tiny"
													trigger={
														<Button
															content={
																isSentToProof ? t("sentToProof") : t("sendToProof")
															}
															disabled={!isReadyForProof || fileTooLong}
															color={isSentToProof ? "green" : "blue"}
															loading={isSendingProof}
															onClick={this.sendToProof}
														/>
													}
												/>
											) : (
												<UploadButton
													style={{ display: "inline-block" }}
													text={t("doUpload")}
													name={article.id}
													multiple={!article.single}
													disabled={
														(article.single && !!this.getFiles(article.id).length) ||
														this.state.analyzingUploads === article.id
													}
													loading={this.state.analyzingUploads === article.id}
													onUpload={this.uploadAudio}
												/>
											)}
										</>
									)}
								</div>
							</div>
						))}
					</DragDropContext>
				)}
			</div>
		);
	}
}

export default withTranslation()(MasterUploads);
